// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"2024-11-14T14:19:20Z"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import {
  captureConsoleIntegration,
  extraErrorDataIntegration,
  init as initSentry,
  rewriteFramesIntegration,
} from "@sentry/nextjs";
import { SentryIgnoreError } from "./src/utils/sentry-ignore-error";

const SENTRY_DSN =
  process.env.APP_ENV !== "local" && process.env.APP_ENV !== "test"
    ? process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
    : null;

const SENTRY_RELEASE =
  process.env.SENTRY_RELEASE || process.env.NEXT_PUBLIC_SENTRY_RELEASE;

const ignoredErrorStrings = [
  // Google maps error
  "EpochMismatch",

  // Segment analytics.js errors
  "Failed to load Analytics.js",
  "Error sending segment performance metrics",

  // Next.JS errors
  "Abort fetching component for route",
  "Cancel rendering route",
  "Route did not complete loading",
  "Loading initial props cancelled",

  // Raised from crawlers - all modern browsers support Intl
  "Intl is not defined",

  // Warmly snippet errors
  "Lead Limit Reached",
  /No session created/i,

  // Seems to be raised from an Outlook extension.  See issue here: https://github.com/getsentry/sentry-javascript/issues/3440
  "Object Not Found Matching Id",

  // Misc
  "ResizeObserver loop limit exceeded",
  "AbortError: enumerateDevices() failed",
];

const hydrationErrorStrings = [
  "An error occurred during hydration.",
  "There was an error while hydrating.",
  "Hydration failed",
  "Text content does not match server-rendered HTML.",
  "Minified React error #418",
  "Minified React error #425",
  "Minified React error #423",
];

initSentry({
  dsn:
    SENTRY_DSN ||
    "https://1df32e6b32534d8c916f84c51d89bc82@o459347.ingest.sentry.io/5585478",
  release: SENTRY_RELEASE,
  integrations: [
    captureConsoleIntegration({
      levels: ["error"],
    }),
    extraErrorDataIntegration(),
    rewriteFramesIntegration({
      iteratee: (frame) => {
        frame.filename = decodeURI(frame.filename);
        return frame;
      },
    }),
  ],
  beforeSend: (event, hint) => {
    const error = hint.originalException;

    if (error && error instanceof SentryIgnoreError) {
      return null;
    }

    if (error && error.message === "Request aborted") {
      return null;
    }

    const errorMessage = typeof error === "string" ? error : error?.message;

    if (
      errorMessage &&
      hydrationErrorStrings.some((str) => errorMessage.includes(str))
    ) {
      event.level = "warning";
    }

    if (event.logger === "console" && event.level === "error") {
      event.level = "warning";
    }

    return event;
  },
  denyUrls: [
    /zoominfo.com/,
    /googletagmanager.com\/gtag/,
    /maps.googleapis.com\/.{0,100}\/webgl.js/,
    /opps-widget.getwarmly.com/,
    /cdn.segment.com/,
    /js.hscollectedforms.net/, // Hubspot forms
    /static.hsappstatic.net/, // Hubspot embedded calendar code
  ],
  ignoreErrors: ignoredErrorStrings,
  tracesSampleRate: 0.3,
});
